<template>
    <div class="container-fluid">
        <div class="mb-2 clearfix">
            <div class="collapse d-md-block display-options" id="displayOptions">               
                <div class="d-block d-md-inline-block"><SearchForm v-model="search" /></div>
                <div class="float-md-right d-flex">
                    <paginatorTop :offset="offset" :libelle='$t("data.occupation_loyers")' :total="occupations.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />
                    <div class="d-flex ">
                        <b-form-select class="form-control" size="sm" @input="getOccupations" v-model="periode.mois" :options="mois" />
                        <b-form-select class="form-control" size="sm" @input="getOccupations" v-model="periode.annee" :options="annees" />
                    </div>
                </div>
            </div>
        </div>
        <p class="font-weight-bold" style="font-size: 22px">{{$t('data.possessions_actuelle')}}
            <span v-if="trueOccupations.length > 1" style="color: #f5365c;">{{ trueOccupations.length }} {{$t('data.logement_detail_locataires')}}.</span>
            <span v-else style="color: #f5365c;">{{ trueOccupations.length }} {{$t('data.occupation_locataire')}}.</span>
        </p>
        <div class="separator mb-20"></div>

        <b-overlay :show="showOverlay" rounded="sm">
            <b-alert variant="info" class="text-center" show v-if="!occupations.length">
                <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                <span class="h4 d-inline-flex ml-2">{{$t('data.occupation_pas_de_occupation')}}</span>
            </b-alert>  
            <b-row v-else>
                <b-col lg="3" md="6" sm="12" v-for="occupation in items" :key="occupation.idOccupation" > 
                    <b-card :title="`${occupation.locataire.nomLocataire} ${occupation.locataire.prenomLocataire}`" :sub-title="`${occupation.logement.refLogement} - ${occupation.logement.sousTypeLogement.libelleSousType}`" class="position-relative">
                        <hr>
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <div>Loyer de base &nbsp; <span class="h6 font-weight-bold">{{ occupation.loyerBase | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</span></div>
                            <b-dropdown variant="outline-danger" right size="sm">
                                <template #button-content><i class="fa fa-ellipsis-h"></i></template>
                                <b-dropdown-item v-if="canViewOccupation" :to="{name: 'occupation', params: {id: occupation.idOccupation}}">{{$t('data.logement_detail_details_de_occupation')}}</b-dropdown-item>
                                <b-dropdown-item v-if="canPaySingleLoyer" href="#" @click.prevent="doPayment(occupation)">{{$t('data.charge_effectuer_un_paiement')}}</b-dropdown-item>
                                <b-dropdown-item v-if="canGenerateCorrespondance" href="#" @click.prevent="correspondance(occupation)">{{$t('data.charge_correspondance')}}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="border pt-0 rounded">
                            <b-table-simple small borderless>
                                <b-tbody>
                                    <b-tr>
                                        <b-td>Arrieres</b-td>
                                        <b-th>{{ occupation.arrieres | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Energies</b-td>
                                        <b-th>{{ occupation.energies | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td>Total</b-td>
                                        <b-th class="font-weight-bold h5">{{ (occupation.energies + occupation.arrieres) | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-th>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <hr>
            <div class="d-flex justify-content-between align-items-start">
                <paginator v-if="occupations.length > 5" :offset="offset" :total="occupations.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />                   
                <b-button class="position-fixed validate btn-icon" id="popover-options" variant="danger"><i class="fa fa-bars fa-lg"></i></b-button>
                <b-popover title="Options" target="popover-options" triggers="click blur" placement="topleft">
                    <div class="py-2">
                        <b-button v-if="canPaysLoyers" block variant="outline-secondary" @click.prevent="autoPay" :disabled="submitted">{{$t('data.loyers_paiement_automatique')}}</b-button>
                        <hr>
                        <p>Envoi automatique des correspondances</p>
                        <b-button block variant="outline-secondary" @click.prevent="sendMassCorrespondancePerMail">
                            <div :disabled="loading">
                                <span v-if="loading" class="loading-indicator"></span>
                                <span v-else><i class="mr-2 text-danger fa fa-envelope"></i>
                                Envoyer par mail
                                </span>
                            </div>
                        </b-button>
                        <b-button block variant="outline-secondary" @click.prevent="sendMassCorrespondancePerSMS">
                            <div :disabled="loading1">
                                <span v-if="loading1" class="loading-indicator"></span>
                                <span v-else><i class="mr-2 text-danger fa fa-sms"></i>
                                Envoyer par sms
                                </span>
                            </div>
                        </b-button>
                        <hr>
                        <b-button v-if="canPrintFicheRecette" block variant="outline-secondary" v-b-modal.modal-xl>{{$t('data.imprimer_fiche_de_recette')}}</b-button>
                        <b-button v-if="canGenerateCorrespondance" block variant="outline-secondary" v-b-modal.correspondances>Imprimer les correspondances</b-button>
                        <hr>
                        <b-button v-if="canGenerateCharges" block variant="outline-secondary" @click.prevent="generateCharges" :disabled="submitted">{{$t('data.loyers_generer_charges')}}</b-button>
                        <b-button v-if="canGenerateLoyers" block variant="outline-secondary" @click.prevent="generateLoyers" :disabled="submitted">Générer les loyers</b-button>
                        <hr>
                        <b-button v-if="canGenerateFactures" block variant="outline-secondary" @click.prevent="printList = true">{{$t("data.loyers_generer_les_factures")}}</b-button>
                    </div>
                </b-popover>
            </div>
        </b-overlay>


        <!--MODAL POUR PAYER UNE FACTURE -->
        <div v-if="occupation">
            <b-modal id="paiement" :title="$t('data.charge_ajouter_paiement')" no-close-on-backdrop @hidden="occupation = null" hide-footer>
                <payer-impayer-form :occupation="occupation" :periodeCourante="periodeCourante" @payed="getOccupations" />
            </b-modal>
        </div>

        <!--MODAL DES CORRESPONDANCES -->
        <div v-if="occupation">
            <b-modal id="correspondance" :title="$t('data.charge_correspondance')" no-close-on-backdrop hide-footer>
                <correspondance :occupation="occupation" :periodeCourante="periodeCourante" />
            </b-modal>
        </div>
        <!--MODAL DES CORRESPONDANCES GROUPEES -->
         <b-modal id="correspondances" no-close-on-backdrop hide-footer size="lg" title="">
            <correspondances :periodeCourante="periodeCourante" :occupations="trueOccupations" />
        </b-modal>

             <!--MODAL IIMPRESSION DES RECETTES NOUVELLE FORMULE -->
         <b-modal id="modal-xl" size="xl" title="Données fiche de recettes" hide-footer>
             <fiche-recette :periodeCourante="periodeCourante" :occupations="trueOccupations" />
        </b-modal>
    </div>
</template>
<script>
import SearchForm from "@/components/parts/SearchForm.vue";
import PayerImpayerForm from '../../components/form/PayerImpayerForm.vue';
const {mois:_mois, annees:_annees, periodeCourante:_periodeCourante} = require('../../helpers/date')

const php  = require ( 'phpjs' ) ; 

import { mapGetters} from "vuex"
import Correspondance from './loyers/Correspondance.vue';
import FicheRecette from './loyers/FicheRecette.vue';
import Correspondances from "./loyers/Correspondances.vue";
export default {
    name: "Loyers",
    components: {
    SearchForm,
    Correspondance,
    PayerImpayerForm,
    FicheRecette,
    Correspondances
},
    data: () => ({
        loading: false,
        loading1: false,
        search: null,
        showOverlay: true,
        submitted: false,

        currentPage: 1,
        perPage: 10,
        occupations:[],
        trueOccupations:[],
        occupation: null,

        periode: {annee: null, mois: null},
    }),
    watch: {
        search(value) {
            this.occupations = !php.empty(value)
                ? this.trueOccupations.filter(elt =>
                    elt.locataire.nomLocataire.toLowerCase().includes(value.toLowerCase()) ||
                    elt.locataire.prenomLocataire.toLowerCase().includes(value.toLowerCase()) ||
                    elt.logement.refLogement.toLowerCase().includes(value.toLowerCase())
                )
                : this.trueOccupations;
        },
  },
  computed: {
        ...mapGetters(['user', 'hasAccess']),
        devise() {
            return /* this.user.preferences.devise ||  */'F'
        },
        /**
         * Elements affichés avec prise en charge de la pagination
         */
        items() { 
            return php.array_slice(this.occupations.filter(elt => {
                return (this.$dayjs(this.periodeCourante).diff(elt.dateDeb, 'month') >= 0 && (php.empty(elt.dateFin) || this.$dayjs(elt.dateFin).diff(this.periodeCourante, 'month') <= 0))
            }), this.offset, this.perPage) 
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },

        mois() {
            return _mois(this.periode.annee).map(elt => ({...elt, text: php.ucfirst(elt.text)}))
        },
        periodeCourante() {
            return _periodeCourante(this.periode)
        },
        annees: () => _annees(),

        canViewOccupation(){
            return this.hasAccess('list_occupations')
        },

        canPaySingleLoyer(){
            return this.hasAccess('pay_loyer')
        },

        canGenerateCorrespondance(){
            return this.hasAccess('generate_correspondance')
        },

        canPaysLoyers(){
            return this.hasAccess('pay_single_loyer')
        },
        canPrintFicheRecette(){
            return this.hasAccess('print_fiche_recette')
        },
        canGenerateFactures() {
             return this.hasAccess('generate_factures')    
        },
        canGenerateCharges() { 
            return this.hasAccess('generate_charges')
        },
        canGenerateLoyers(){
            return this.hasAccess('generate_loyers')
        }
    },

    beforeMount() {
        this.periode = {
            mois: parseInt(this.$dayjs().format('M')) - 1,
            annee: parseInt(this.$dayjs().format('YYYY'))
        }
        this.getOccupations(true);
    },
    
    methods: {
        getOccupations(init = false) {
            this.showOverlay = true
            this.$bvModal.hide('paiement')
            this.pages = []
            axios.get('loyers/generate?periode='+this.periodeCourante).then(response => response.result || []).then(occupations => {
                occupations = occupations.sort((a, b) => (a.logement.refLogement < b.logement.refLogement) ? -1 : 1)
                this.occupations = this.trueOccupations = [...occupations]
                this.showOverlay = false
            });
        },

        doPayment(occupation) {
            this.occupation = occupation 
            setTimeout(() => {
                this.$bvModal.show('paiement')
            }, 50);
        },
        runPaiement(bvtEvt) {
            bvtEvt.preventDefault()
            this.runPaiementFacture = true
        },

        correspondance(occupation) {
            this.occupation = occupation
            setTimeout(() => {
                this.$bvModal.show('correspondance')
            }, 50);
        },

        /**
         * Lance le paiement automatique des facture
         */
        autoPay() {
            this.showOverlay = true 
            axios.post('loyers/auto-pay', {periode: this.periodeCourante}).then(response => {
                this.showOverlay = false 
                return App.alertSuccess(response.message, {callback: () => {
                    this.getOccupations()
                }})
            }).catch(error => {
                this.showOverlay = false
                return App.alertError(error.message)
            })
        },
        generateCharges() {
            this.showOverlay = true 
            axios.post('charges/auto-generate', {periode: this.periodeCourante}).then(response => {
                this.showOverlay = false 
                return App.alertSuccess(response.message, {callback: () => {
                    this.getOccupations()
                }})
            }).catch(error => {
                this.showOverlay = false
                return App.alertError(error.message)
            })
        },

        generateLoyers() {
            this.showOverlay = true
            axios.post('loyers/generate', {periode: this.periodeCourante}).then(response => {
                this.showOverlay = false 
                return App.alertSuccess(response.message, {callback: () => {
                    this.getOccupations()
                }})
            }).catch(error => {
                this.showOverlay = false
                return App.alertError(error.message)
            })
        },
        sendMassCorrespondancePerMail() {
            this.loading = true
            this.showOverlay = true
            axios
                .post("send/masse/mail/correspondances", {periode: this.$dayjs(this.periodeCourante).format("MMMM YYYY")})
                .then((response) => {
                    App.notifySuccess(response.message);
                    this.showOverlay = false;
                    this.loading = false;
                })
                .catch((error) => {
                    this.submitted = false;
                    return App.alertError(
                        error.message || "Impossible de joindre le serveur"
                    );
                });
        },
        sendMassCorrespondancePerSMS() {
            this.loading1 = true
            this.showOverlay = true
            axios
                .post("send/masse/sms/correspondances", {periode: this.$dayjs(this.periodeCourante).format("MMMM YYYY")})
                .then((response) => {
                    App.notifySuccess(response.message);
                    this.showOverlay = false;
                    this.loading1 = false;
                })
                .catch((error) => {
                    this.submitted = false;
                    return App.alertError(
                        error.message || "Impossible de joindre le serveur"
                    );
                });
    },
        }
    }
</script>
<style scoped>
td.border, th.border {
    border: 1px solid #000 !important;
}
    .with-top-border > td {
    border-top: 2px solid black !important;
    }
    .text-form-occupation {
        font-size: 1.3em;
        color: #212121ef;
        font-weight: 800;
        text-align: center;
        margin-top: 2px;
    }
button.validate{
    bottom: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    box-shadow: 3px 1px 1px gray;
}

/**css fichier de recettes */
.b-table .my-left-border {
  border-left: 3px solid #000;
}

.show-menu{
    background-color: red !important;
    border-radius: 50px;
    width: 400px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.loading-indicator::after {
  content: "";
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 3px solid #202020;
  border-top-color: white;
  border-bottom-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
}
</style>