<template>
  <div>
    <b-alert v-if="occupation.dateFin" show variant="info" class="text-center">
      <i class="fa fa-exclamation-triangle fa-5x"></i> <br />
      <p class="fa-2x mt-5 pt-2">
        {{ $t("data.logement_detail_bail_termine_le") }}
        {{ $dayjs(occupation.dateFin).format("dddd, DD MMMM YYYY") }}
      </p>
    </b-alert>

    <printer :display="true" v-else v-model="printSingle" @input="printSingle = false" id="print-correspondance">
      <div class="d-flex text-center flex-column align-items-center mb-4">
        <h5 class="m-0 text-danger font-weight-bold">
          Correspondance du mois de
          {{ $dayjs(periodeCourante).format("MMMM YYYY") }}
        </h5>
        <hr class="mb-0 mt-1 w-50" size="10" />
      </div>
      <div class="font-weight-bold h6 my-3">
        {{ entreprise.nom }} {{ entreprise.raisonSociale }} <br />
        RCCM N° {{ entreprise.registreCommerce }} <br />
        {{ entreprise.siegeSocial }} <br />
        {{ entreprise.email }} <br />
        {{ entreprise.tel }} <br />
      </div>
      <p>
        <b>{{
      occupation.locataire.titre +
      " " +
      occupation.locataire.nomLocataire +
      " " +
      occupation.locataire.prenomLocataire
    }}
          ({{ occupation.locataire.tel }})</b>, Votre correspondance pour le logement de référence
        <b>{{ occupation.logement.refLogement }}</b> (<i>{{
      occupation.logement.sousTypeLogement.libelleSousType
    }}</i>).
      </p>
      <h5 class="font-weight-bold text-center">Informations</h5>
      <table class="w-100">
        <thead>
          <tr>
            <td style="border: 0 !important"></td>
            <th>{{ $t("data.occupation_montant") }}</th>
            <th>{{ $t("data.occupation_avance") }}</th>
            <th>{{ $t("data.occupation_reste") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{ $t("data.charge_loyer_de_base") }}</th>
            <td>
              {{
      occupation.loyerBase
      | currency(devise, 0, {
        symbolOnLeft: false,
        spaceBetweenAmountAndSymbol: true,
        thousandsSeparator: ".",
      })
    }}
            </td>
            <td>
              {{
        hasBuyLoyer[1]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        (occupation.loyerBase - hasBuyLoyer[1])
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("data.occupation_charges") }}</th>
            <td>
              {{
      hasBuyCharges[2]
      | currency(devise, 0, {
        symbolOnLeft: false,
        spaceBetweenAmountAndSymbol: true,
        thousandsSeparator: ".",
      })
    }}
            </td>
            <td>
              {{
        hasBuyCharges[1]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        (hasBuyCharges[2] - hasBuyCharges[1])
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
          <tr>
            <th>Conso. eau</th>
            <td>
              {{
        hasBuyEau[1]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        hasBuyEau[2]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        (hasBuyEau[1] - hasBuyEau[2])
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
          <tr>
            <th>Conso. électr.</th>
            <td>
              {{
        hasBuyEnergie[1]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        hasBuyEnergie[2]
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td>
              {{
        (hasBuyEnergie[1] - hasBuyEnergie[2])
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("data.ionsolvable_total_insolvable") }}</th>
            <td>
              {{
      (occupation.loyerBase +
        hasBuyCharges[2] +
        hasBuyEau[1] +
        hasBuyEnergie[1])
      | currency(devise, 0, {
        symbolOnLeft: false,
        spaceBetweenAmountAndSymbol: true,
        thousandsSeparator: ".",
      })
    }}
            </td>
            <td>
              {{
        (hasBuyLoyer[1] +
          hasBuyCharges[1] +
          hasBuyEau[2] +
          hasBuyEnergie[2])
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
            <td class="font-weight-bold">
              {{
        totalQuitance
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
          <tr>
            <th colspan="3">Arrierés</th>
            <td class="font-weight-bold text-right">
              {{
        occupation.arrieres
        | currency(devise, 0, {
          symbolOnLeft: false,
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
        })
      }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3">{{ $t("data.loyers_montant_net_a_payer") }}</th>
            <td class="font-weight-bold text-right text-danger fa-lg">
              {{
      netAPayer
      | currency(devise, 0, {
        symbolOnLeft: false,
        spaceBetweenAmountAndSymbol: true,
        thousandsSeparator: ".",
      })
    }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="mt-3">
        <p>
          En foi de quoi ce documment est délivré pour servir et faire valoir ce
          que de droit.
        </p>
        <br /><br /><br /><br />
        <div class="d-flex justify-content-end">
          Fait le: <b class="ml-1">{{ $dayjs().format("DD-MM-YYYY") }}</b>
        </div>
      </div>
    </printer>

    <div class="float-right position-relative">
      <b-popover class="show-menu" title="Partager" target="partager" triggers="click blur" placement="topleft">
        <div class="py-3 px-2">
          <b-link class="mb-1 d-inline-block w-100" @click.prevent="sendPerMail">
            <div :disabled="loading">
              <span v-if="loading" class="loading-indicator"></span>
              <span v-else><i class="mr-2 text-danger fa fa-envelope"></i>
                {{ $t("data.correspondance_envoyer_par_mail") }}</span>
            </div>
          </b-link>
          <br />
          <b-link class="mb-1 d-inline-block w-100" @click.prevent="sendPerSMS">
            <div :disabled="loading1">
              <span v-if="loading1" class="loading-indicator"></span>
              <span v-else><i class="mr-2 text-danger fa fa-sms"></i>
                {{ $t("data.correspondance_envoyer_par_sms") }}</span>
            </div>
          </b-link>
          <br />
          <ShareNetwork v-for="network in networks" :key="network.network" :network="network.network" url=""
            :title="sharing.title" :description="sharing.description">
            <b-link class="mb-1 d-inline-block w-100">
              <i class="mr-2 text-danger" :class="network.icon"></i>
              {{ network.name }}
            </b-link>
            <br />
          </ShareNetwork>
          <b-link class="mb-1 d-inline-block w-100" @click.prevent="printSingle = true">
            <i class="mr-2 text-danger fa fa-print"></i>
            {{ $t("data.correspondance_envoyer_par_imprimer_facture") }}
          </b-link>
        </div>
      </b-popover>
      <b-button id="partager" v-b-tooltip.bottom="$t('data.annonce_menu_tooltip_annonce')" variant="danger"><i
          class="fa fa-ellipsis-h"></i></b-button>
    </div>
  </div>
</template>

<script>
const php = require("phpjs");
const {
  check_if_buy_loyer,
  check_if_buy_consommation,
  check_if_buy_charges,
} = require("../../../helpers/loyers");

import Printer from "@/components/Printer.vue";
import { mapGetters } from "vuex";
import { ShareNetwork } from "vue-social-sharing";
export default {
  name: "Correspondance",
  components: {
    Printer,
    ShareNetwork,
  },
  props: {
    occupation: { type: Object, required: true },
    periodeCourante: { type: String, required: true },
  },
  data: () => ({
    loading: false,
    loading1: false,
    printSingle: false,
    networks: [
      { network: "whatsapp", name: "Whatsapp", icon: "fab fa-whatsapp" },
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
    entreprise() {
      return this.user.entreprises.find(
        (elt) => elt.idEntreprise == storage.get("selected_enterprise")
      );
    },
    devise() {
      return /* this.user.preferences.devise ||  */ "F";
    },
    hasBuyLoyer() {
      console.log("Occupation ===================================>",this.occupation)
      return check_if_buy_loyer(this.occupation, this.periode_loyer);
    },
    hasBuyEau() {
      return check_if_buy_consommation(
        this.occupation,
        this.periodeCourante,
        "eau"
      );
    },
    hasBuyEnergie() {
      return check_if_buy_consommation(
        this.occupation,
        this.periodeCourante,
        "energie"
      );
    },
    hasBuyCharges() {
      return check_if_buy_charges(this.occupation, this.periodeCourante);
    },

    totalLoyer() {
      return this.occupation.loyerBase - this.hasBuyLoyer[1];
    },
    totalCharge() {
      return this.hasBuyCharges[2] - this.hasBuyCharges[1];
    },
    totalEnergie() {
      return this.hasBuyEnergie[1] - this.hasBuyEnergie[2];
    },
    totalEau() {
      return this.hasBuyEau[1] - this.hasBuyEau[2];
    },
    totalQuitance() {
      return (
        this.totalLoyer + this.totalCharge + this.totalEnergie + this.totalEau
      );
    },
    netAPayer() {
      return this.totalQuitance + this.occupation.arrieres;
    },

    adresse() {
      const logement = this.occupation.logement;
      let adresse = {};
      if (!php.empty(logement.adresse)) {
        adresse = logement.adresse;
      } else if (!php.empty(logement.batiment)) {
        if (!php.empty(logement.batiment.adresse)) {
          adresse = logement.batiment.adresse;
        }
      }
      if (php.empty(adresse)) {
        return "";
      }
      return (
        (adresse.ville || "") +
        " " +
        (adresse.pays || "") +
        ", " +
        (adresse.quartier || "")
      );
    },
    periode_loyer() {
      if (this.occupation.modePaiement == "prepayer") {
        return this.$dayjs(this.periodeCourante).add(1, "month");
      }
      return this.$dayjs(this.periodeCourante);
    },
    sharing() {
      const locataire =
        this.occupation.locataire.titre +
        " " +
        this.occupation.locataire.nomLocataire +
        " " +
        this.occupation.locataire.prenomLocataire,
        logement =
          this.occupation.logement.refLogement +
          "/" +
          this.occupation.logement.sousTypeLogement.libelleSousType;

      return {
        title: locataire + ", vous occupez le logement " + logement + ".",
        description:
          "Le montant total de votre facture du mois de " +
          this.$dayjs(this.periodeCourante).format("MMMM YYYY") +
          " s'élève à " +
          this.netAPayer +
          " " +
          this.devise +
          " repartit comme suit : \
                    \nLoyer : " +
          this.totalLoyer +
          "\
                    \nCharges : " +
          this.totalCharge +
          "\
                    \nFacture Eau : " +
          this.totalEau +
          "\
                    \nFacture d'electricité :" +
          this.totalEnergie +
          "\
                    \nArrierés : " +
          this.occupation.arrieres,
        quote: "",
      };
    },
  },

  methods: {
    async sendPerMail() {
      this.loading = true;

      const entreprise = this.entreprise;
      const occupation = this.occupation;
      const devise = this.devise;
      const hasBuyLoyer = this.hasBuyLoyer;
      const hasBuyCharges = this.hasBuyCharges;
      const hasBuyEau = this.hasBuyEau;
      const hasBuyEnergie = this.hasBuyEnergie;
      const data = {
        periode: this.$dayjs(this.periodeCourante).format("MMMM YYYY"),
        entreprise: {
          nom: entreprise.nom,
          raisonSociale: entreprise.raisonSociale,
          registreCommerce: entreprise.registreCommerce,
          siegeSocial: entreprise.siegeSocial,
          email: entreprise.email,
          tel: entreprise.tel,
        },
        locataire: {
          titre: occupation.locataire.titre,
          nom: occupation.locataire.nomLocataire,
          prenom: occupation.locataire.prenomLocataire,
          tel: occupation.locataire.tel,
          email: occupation.locataire.mail,
        },
        logement: {
          refLogement: occupation.logement.refLogement,
          libelleSousType: occupation.logement.sousTypeLogement.libelleSousType,
        },
        devise: devise,
        loyerBase: {
          montant: occupation.loyerBase,
          avance: hasBuyLoyer[1],
          reste: occupation.loyerBase - hasBuyLoyer[1],
        },
        charges: {
          montant: hasBuyCharges[2],
          avance: hasBuyCharges[1],
          reste: hasBuyCharges[2] - hasBuyCharges[1],
        },
        consommationEau: {
          montant: hasBuyEau[1],
          avance: hasBuyEau[2],
          reste: hasBuyEau[1] - hasBuyEau[2],
        },
        consommationElectrique: {
          montant: hasBuyEnergie[1],
          avance: hasBuyEnergie[2],
          reste: hasBuyEnergie[1] - hasBuyEnergie[2],
        },
        total: {
          montant:
            occupation.loyerBase +
            hasBuyCharges[2] +
            hasBuyEau[1] +
            hasBuyEnergie[1],
          avance:
            hasBuyLoyer[1] + hasBuyCharges[1] + hasBuyEau[2] + hasBuyEnergie[2],
          reste: this.totalQuitance,
        },
        arrieres: {
          reste: occupation.arrieres,
        },
        netAPayer: {
          reste: this.netAPayer,
        },
        date: this.$dayjs().format("DD-MM-YYYY"),
      };

      axios
        .post("send/mail/correspondance", data)
        .then((response) => {
          App.notifySuccess(response.message);
          this.showOverlay = true;
          this.loading = false;
        })
        .catch((error) => {
          this.submitted = false;
          return App.alertError(
            error.message || "Impossible de joindre le serveur"
          );
        });
    },
    async sendPerSMS() {
      this.loading1 = true;

      const entreprise = this.entreprise;
      const occupation = this.occupation;
      const devise = this.devise;
      const hasBuyLoyer = this.hasBuyLoyer;
      const hasBuyCharges = this.hasBuyCharges;
      const hasBuyEau = this.hasBuyEau;
      const hasBuyEnergie = this.hasBuyEnergie;
      const data = {
        periode: this.$dayjs(this.periodeCourante).format("MMMM YYYY"),
        entreprise: {
          nom: entreprise.nom,
          raisonSociale: entreprise.raisonSociale,
          registreCommerce: entreprise.registreCommerce,
          siegeSocial: entreprise.siegeSocial,
          email: entreprise.email,
          tel: entreprise.tel,
        },
        locataire: {
          titre: occupation.locataire.titre,
          nom: occupation.locataire.nomLocataire,
          prenom: occupation.locataire.prenomLocataire,
          tel: occupation.locataire.tel,
          email: occupation.locataire.email,
        },
        logement: {
          refLogement: occupation.logement.refLogement,
          libelleSousType: occupation.logement.sousTypeLogement.libelleSousType,
        },
        devise: devise,
        loyerBase: {
          montant: occupation.loyerBase,
          avance: hasBuyLoyer[1],
          reste: occupation.loyerBase - hasBuyLoyer[1],
        },
        charges: {
          montant: hasBuyCharges[2],
          avance: hasBuyCharges[1],
          reste: hasBuyCharges[2] - hasBuyCharges[1],
        },
        consommationEau: {
          montant: hasBuyEau[1],
          avance: hasBuyEau[2],
          reste: hasBuyEau[1] - hasBuyEau[2],
        },
        consommationElectrique: {
          montant: hasBuyEnergie[1],
          avance: hasBuyEnergie[2],
          reste: hasBuyEnergie[1] - hasBuyEnergie[2],
        },
        total: {
          montant:
            occupation.loyerBase +
            hasBuyCharges[2] +
            hasBuyEau[1] +
            hasBuyEnergie[1],
          avance:
            hasBuyLoyer[1] + hasBuyCharges[1] + hasBuyEau[2] + hasBuyEnergie[2],
          reste: this.totalQuitance,
        },
        arrieres: {
          reste: occupation.arrieres,
        },
        netAPayer: {
          reste: this.netAPayer,
        },
        dateLimite:
          this.$dayjs(occupation.dateFin).format("DD MM YYYY") | "---",
        date: this.$dayjs().format("DD-MM-YYYY"),
      };

      axios
        .post("send/sms/correspondance", data)
        .then((response) => {
          App.notifySuccess(response.message);
          this.showOverlay = true;
          this.loading1 = false;
        })
        .catch((error) => {
          this.submitted = false;
          return App.alertError(
            error.message || "Impossible de joindre le serveur"
          );
        });
    },
  },
};
</script>

<style scoped>
td,
th {
  border: 2px solid black !important;
  padding: 5px;
  text-align: right;
  width: 25%;
}

tbody th {
  text-align: left;
  font-weight: lighter;
}

thead th {
  text-align: center;
}

.loading-indicator::after,
.loading-indicator1::after {
  content: "";
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 3px solid #202020;
  border-top-color: white;
  border-bottom-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-indicator,
.loading-indicator1 {
  display: flex;
  justify-content: center;
}
</style>
