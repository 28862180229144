<template>
    <div>
        <header class="d-flex border rounded px-3 py-1 align-items-center">
            <img :src="`${$router.options.base}img/logo-m0.png`" class="header-brand-img" alt="lavalite" style="height: 5em; width: 5em">
            <div style="flex: 1;" class="ml-1 text-center">
                <h2 class="text-danger">Maisonier</h2>   
                <p class="text-muted">{{$t('data.printer_definition_maisonier')}}</p>
            </div> 
        </header>
        <h5 class="mb-1">{{$t('data.charge_label_loyer_du_mois_de')}} :<b class="pl-2">{{ $dayjs(this.periodeCourante + 1).format('MMMM YYYY') }}</b></h5>
            
        <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
            <b-tbody>
                <b-tr class=" with-top-border" style="border: 2px solid black!important">
                    <b-th  class="border m-0 p-0" style="text-align:center border: 2px solid black!important">{{$t('data.detail_habitant_numero')}}</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center border: 2px solid black!important">Ref</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center ; width: 9%; border: 2px solid black!important">{{$t('data.occupation_informations_loyer')}}</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center ; width: 16%; border: 2px solid black!important">NOMS & PRENOMS</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center; border: 2px solid black!important">{{$t('data.arrieres')}}</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center; width: 9%; border: 2px solid black!important">{{$t('data.energie')}}</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center; border: 2px solid black!important"><span>CUMULS</span><br><span>IMPAYES</span>  </b-th>
                    <b-th class="border m-0 p-0" style="text-align:center; width: 14%">{{$t('data.recettes')}}</b-th>
                    <b-th class="border m-0 p-0" style="text-align:center; width: 18%; border: 2px solid black!important">{{$t('data.occupation_observation')}}</b-th>
                    <b-th class="border m-0 py-0 px-1" style="text-align:center; width: 16%; border: 2px solid black!important">{{$t('data.contacts')}}</b-th>
                </b-tr>
                <b-tr style="border: 2px solid black!important" class=" with-top-border" v-for="(occupation, index) in occupations" :key="occupation.idOccupation">
                    <b-td class="border p-0" style="border: 2px solid black!important">
                        <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++index }}</span>    
                    </b-td>
                    <b-td class="border px-2 py-0" style="border: 2px solid black!important"><span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ occupation.logement.refLogement }}</span></b-td>
                    <b-td class="border py-0 px-1 text-center font-weight-bold" style="text-align:center; border: 2px solid black!important">{{ occupation.loyerBase | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                    <b-td class="border py-0">
                        <span class="d-inline-block">{{ nomComplet(occupation.locataire) }}</span>    
                    </b-td>
                    <b-td class="border p-0" style="text-align:center; border: 2px solid black!important" v-bind:class="{ 'text-danger': occupation.arrieres > 200000 }"><b>{{ occupation.arrieres | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                    <b-td class="border p-0" style="text-align:center; border: 2px solid black!important"><b>{{ occupation.energies | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                    <b-td class="border p-0" style="text-align:center; border: 2px solid black!important" v-bind:class="{ 'text-danger': (occupation.arrieres + occupation.energies) > 200000 }"><b>{{ (occupation.arrieres + occupation.energies) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                    <b-td class="border p-0" style="border: 2px solid black!important"></b-td>
                    <b-td class="border p-0 position-relative" style="border: 2px solid black!important" v-html="observation(occupation)"></b-td>
                    <b-td class="border p-0" style="border: 2px solid black!important" v-html="locataireTel(occupation.locataire)"></b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <br>
        <b-button variant="danger" class="float-right mt-1" @click="$refs.html2Pdf.generatePdf()"><i class="fa fa-print"></i> Imprimer</b-button>

        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="fiche_recettes"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            :htmlToPdfOptions="htmlToPdfOptions"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <header class="d-flex border rounded px-3 py-1 align-items-center">
                    <img :src="`${$router.options.base}img/logo-m0.png`" class="header-brand-img" alt="lavalite" style="height: 5em; width: 5em">
                    <div style="flex: 1;" class="ml-1 text-center">
                        <h2 class="text-danger">Maisonier</h2>   
                        <p class="text-muted">{{$t('data.printer_definition_maisonier')}}</p>
                    </div> 
                </header>
                <h5 class="mb-1">{{$t('data.charge_label_loyer_du_mois_de')}} :<b class="pl-2">{{ $dayjs(this.periodeCourante + 1).format('MMMM YYYY') }}</b></h5> 

                <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                    <b-tbody>
                        <b-tr>
                            <b-th class="border m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                            <b-th class="border m-0 p-0 text-center">Ref</b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 9%">{{$t('data.occupation_informations_loyer')}}</b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 16%">NOMS & PRENOMS</b-th>
                            <b-th class="border m-0 p-0 text-center">{{$t('data.arrieres')}}</b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 9%">{{$t('data.energie')}}</b-th>
                            <b-th class="border m-0 p-0 text-center"><span>CUMULS</span><br><span>IMPAYES</span>  </b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 14%">{{$t('data.recettes')}}</b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 18%">{{$t('data.occupation_observation')}}</b-th>
                            <b-th class="border m-0 p-0 text-center" style="width: 16%">{{$t('data.contacts')}}</b-th>                
                        </b-tr>
                        <!--<b-tr><td colspan="5">&nbsp;</td></b-tr>-->
                        <b-tr v-for="(occupation, i) in fisrtPage" :key="i">
                            <b-td class="border p-0">
                                <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++i }}</span>    
                            </b-td>
                            <b-td class="border p-0 px-2">
                                <!--<span class="d-inline-block w-100 mb-1 font-weight-bold" style="text-align:center">{{ occupation.logement.refLogement }}</span>-->
                                <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ occupation.logement.refLogement }}</span>
                            </b-td>
                            <b-td class="border p-0 px-1 text-center font-weight-bold" style="text-align:center">{{ occupation.loyerBase | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                            <b-td class="border py-0">
                                <span class="d-inline-block font-weight-bold">{{ nomComplet(occupation.locataire) }}</span>    
                            </b-td>
                            <b-td class="border py-0 text-center" v-bind:class="{ 'text-danger': calculate(occupation.arrieres) > 200000 }"><b>{{ calculate(occupation.arrieres) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>

                            <!--<b-td class="border py-0 text-center" v-bind:class="{ 'text-danger': occupation.arrieres > 200000 }"><b>{{ occupation.arrieres | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>-->
                            <b-td class="border py-0 text-center" style="width: 7%"><b>{{ occupation.energies | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                            <b-td class="border py-0 text-center" v-bind:class="{ 'text-danger': (calculate(occupation.arrieres) + occupation.energies) > 200000 }"><b>{{ (calculate(occupation.arrieres) + occupation.energies) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                            <b-td class="border py-0" style="width: 14%"></b-td>
                            <b-td class="border py-0" style="width: 20%" v-html="observation(occupation)"></b-td>
                            <b-td class="border py-0" v-html="locataireTel(occupation.locataire)"></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                
                <!--ETABLISSEMENT DU DEUXIEME BREAK-->
                <div class="html2pdf__page-break p-0 m-0"></div>
                <div v-for="(page, i) in pages" :key="i">
                    <!--DEBUT DEUXIEME PAGE-->
                    <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                        <b-tbody>
                            <b-tr style="display : none">
                                <b-th class="border m-0 text-center p-0">{{$t('data.detail_habitant_numero')}}</b-th>
                                <b-th class="border m-0 text-center p-0">Ref</b-th>
                                <b-th class="border m-0 text-center p-0" style="width: 9%">{{$t('data.occupation_informations_loyer')}}</b-th>
                                <b-th class="border m-0 text-center p-0" style="width: 16%">NOMS & PRENOMS</b-th>
                                <b-th class="border m-0 text-center p-0">{{$t('data.arrieres')}}</b-th>
                                <b-th class="border m-0 text-center p-0" style="width: 7%">{{$t('data.energie')}}</b-th>
                                <b-th class="border m-0 text-center p-0"><span>CUMULS</span><br><span>IMPAYES</span>  </b-th>
                                <b-th class="border m-0 text-center p-0" style="width: 14%">{{$t('data.recettes')}}</b-th>
                                <b-th class="border m-0 text-center p-0" style="width: 20%">{{$t('data.occupation_observation')}}</b-th>
                                <b-th class="border m-0 text-center py-0 px-1" style="width: 16%">{{$t('data.contacts')}}</b-th>                
                            </b-tr>
                            <!--<b-tr><td colspan="5">&nbsp;</td></b-tr>-->
                            <b-tr v-for="(occupation, j) in page" :key="occupation.idOccupation">
                                <b-td class="border py-0">
                                    <span class="d-inline-block w-100 font-weight-bold text-center">{{ limitFistPage + (++j) + ((i + 1) * printLimit - printLimit) }}</span>    
                                </b-td>
                                <b-td class="border py-0 px-2">
                                    <span class="d-inline-block w-100 font-weight-bold">{{ occupation.logement.refLogement }}</span>
                                </b-td>
                                <b-td class="border py-0 px-1 text-center font-weight-bold">{{ occupation.loyerBase | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                <b-td class="border py-0 ">
                                    <span class="d-inline-block font-weight-bold">{{ nomComplet(occupation.locataire) }}</span>    
                                </b-td>
                                <b-td class="border py-0 text-center" v-bind:class="{ 'text-danger': calculate(occupation.arrieres) > 200000 }"><b>{{ calculate(occupation.arrieres) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                                <b-td class="border py-0 text-center" style="width: 7%"><b>{{ occupation.energies | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                                <b-td class="border py-0 text-center" v-bind:class="{ 'text-danger': (calculate(occupation.arrieres) + occupation.energies) > 200000 }"><b>{{ (calculate(occupation.arrieres) + occupation.energies) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b></b-td>
                                <b-td class="border py-0 text-center" style="width: 14%"></b-td>
                                <b-td class="border py-0 text-center position-relative px-0 m-0" style="width: 20%" v-html="observation(occupation)"></b-td>
                                <b-td class="border py-0" v-html="locataireTel(occupation.locataire)"></b-td>
                            </b-tr>
                            <b-tr v-if="i == pages.length - 1">
                                <b-td class="border text-center" colspan="2">
                                    <span class="d-inline-block w-100 font-weight-bold">Totaux</span>    
                                </b-td>
                                <b-td class="border text-center">
                                    <span class="d-inline-block w-100 font-weight-bold">{{ totalLoyers | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>
                                </b-td>
                                <b-td class="border font-weight-bold" style="width: 16%"></b-td>
                                <b-td class="border text-center">
                                    <span class="d-inline-block font-weight-bold ">{{ totalArrieres | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>    
                                </b-td>
                                <b-td class="border text-center">
                                    <span class="d-inline-block font-weight-bold ">{{ totalEnergies | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>    
                                </b-td>
                                <b-td class="border text-center">
                                    <span class="d-inline-block font-weight-bold ">{{ montantAttendu | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>    
                                </b-td>
                                <b-td class="border" colspan="3"></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <!--ETABLISSEMENT DU DEUXIEME BREAK-->
                    <div class="html2pdf__page-break p-0 m-0" v-if="i != 0"></div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>

const php  = require ( 'phpjs' ) ; 

export default {
    name: 'FicheRecette',
    props: {
        periodeCourante: {type: String, required: true},
        occupations: {type: Array, required: true}
    },
    data: () => ({
        htmlToPdfOptions: {
            margin: [7,5,7,5],
            filename: `fiche_recettes.pdf`,       
            image: {
                type: 'jpeg', 
                quality: 2
            },        
            enableLinks: false,
            html2canvas: {
                scale: 4, 
                logging: true,
                useCORS: false
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'landscape',
            }
        },

        pages: [],
        fisrtPage: [],
        printLimit: 35,
        limitFistPage: 27,
    }),
    computed: {
        totalLoyers() {
            return this.occupations.reduce((acc, curr) => acc + curr.loyerBase, 0)
        },
        totalEnergies() {
            return this.occupations.reduce((acc, curr) => acc + curr.energies, 0)
        },
        totalArrieres() {
            return this.occupations.reduce((acc, curr) => acc + curr.arrieres, 0)
        },
        montantAttendu() {
            return this.totalArrieres + this.totalEnergies
        }
    },
    mounted() {
        this.getLogementsLibre()
    },
    methods: {
        calculate(data){
            return data;
        },
        nomComplet(locataire) {
            let toutLenom = (locataire.nomLocataire || '') + ' ' + (locataire.prenomLocataire || '');
            let commande = toutLenom.split(' ')
            
            if (commande.length>=2){
                return commande[0]+' '+commande[1];//+' '+String(commande[2]).charAt(0);
            }
            return toutLenom;
        },
        locataireTel(locataire){
            if(locataire.tel!=null&&locataire.tel2!=null&&locataire.tel!=''&&locataire.tel2!=''){
                return  `<span class="d-inline-block w-100">${locataire.tel}<b> / </b>${locataire.tel2}</span>`

            }else{
                if(locataire.tel!=null&&locataire.tel!=''){
                    return `<span clas="d-inline-block w-100">${locataire.tel}</span>`
                }
                return ''
            }
        },
        observation(occupation){
           if(occupation.lastMonth!==''&&occupation.lastMonth!==undefined){
                let annee =occupation.lastMonth.split('-')[0]
                if(this.$dayjs().format('YYYY')==annee){
                     return `<h6 class="m-0 p-0"><small class="font-weight-bold m-0 p-0" style="position: absolute; bottom:0; right:0;font-size: 0.6rem;padding-right:0.5px">${this.$dayjs(occupation.lastMonth).format('MMM')}</small></h6>`
                }else{
                    return `<h6 class="m-0 p-0"><small class="font-weight-bold m-0 p-0" style="position: absolute; bottom:0; right:0;font-size: 0.6rem;padding-right:0.5px">${this.$dayjs(occupation.lastMonth).format('MMM-YY')}</small></h6>`
                }
                   
            }else{
                if(occupation.solde!==''&&occupation.solde>0){
                    return `<h6 class="m-0 p-0"><small class="font-weight-bold m-0 p-0" style="position: absolute; bottom:0; right:0;font-size: 0.6rem;padding-right:0.5px">${occupation.solde} F</small></h6>`
                }
                else{ return ''}
                
            }  
        },
        getLogementsLibre() {
            let occupations = [...this.occupations]
            axios.get('logements?free=true').then(response => response.result).then(logements => {
                const logementsOccupes = this.occupations.map(elt => elt.idLogement)
                for (const logement of logements) {
                    if (!logementsOccupes.includes(logement.idLogement)) {
                        occupations.push({
                            idLogement: logement.idLogement,
                            logement, locataire: {},
                            loyerBase: '', arrieres:'', energies:'',
                        })
                    }
                }
                occupations = occupations.sort((a, b) => (a.logement.refLogement < b.logement.refLogement) ? -1 : 1)
                occupations = occupations.map( elt =>{
                    if(elt.idOccupation ==418){
                        elt.arrieres = elt.arrieres -10000
                    }
                    return elt 
                })
                console.log('occ',occupations);
                this.pages.push(php.array_slice(occupations, 0, this.limitFistPage))
                const nbrPage = Math.ceil(occupations.length - this.limitFistPage / this.printLimit)

                for (let i = 1; i <= nbrPage; i++) {
                    this.pages.push(
                        php.array_slice(
                            occupations, 
                            ((i * this.printLimit) - this.printLimit) + this.limitFistPage, this.printLimit
                        )
                    )
                }
                this.pages = this.pages.filter(elt => elt.length > 0)
                this.fisrtPage = this.pages.shift()
      
            })
        },

        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(12)
                    pdf.setTextColor(150)
                    pdf.text('Copyright © '+this.$dayjs().format('YYYY')+' By Flysoft engineering | All Rights Reserved.', 10, (pdf.internal.pageSize.getHeight() - 2.9))
                    pdf.text('Page ' + i + ' sur ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2.9))
                } 
            }).save()
        },
    }
}
</script>