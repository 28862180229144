<template>
    <div id="print-area" :class="{'displayed' : display == true }">
        <div :id="id">
            <header class="d-flex justify-content-between rounded px-3 py-1 align-items-center">
                <div class="d-flex align-items-center">
                    <img :src="entreprise.logo" class="header-brand-img" :alt="entreprise.nom" style="height: 4em; width: 4em">
                    <h5 class="ml-2 my-0 font-weight-bold">{{ entreprise.nom }} {{ entreprise.raisonSociale }}</h5>
                </div>
                <div>
                    <h6>L'immobilier au coeur de la vie</h6>
                    <b-progress :value="15" variant="danger" height="5px" :max="100" />
                </div>
            </header>
            <div class="my-3">
                <slot />
            </div>
            <footer class="d-flex flex-column">
                <b-progress :value="15" variant="danger" height="8px" :max="100" />
                <div class="d-flex align-items-center justify-content-between my-2">
                    <div class="d-flex align-items-center">
                        <img src="/img/logo-m0 (2).png" class="header-brand-img" :alt="entreprise.nom" style="height: 3em; width: 3em">
                        <h6 class="ml-2">(c) All right reserved Flysoft Engineering</h6>
                    </div>
                    <h5 class="font-weight-bold">{{ entreprise.nom }} {{ entreprise.raisonSociale }}</h5>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import { mapGetters } from 'vuex';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
    '/lib/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

export default {
    name: 'Printer',
    props: {
        value: {type: Boolean, default: false},
        display: {type: Boolean, default: false},
        id: {type: String, required: true}
    },
    watch: {
        value(v) {
            if (v == true && this.proceced == false) {
                this.runPrint()
            }
        }
    }, 
    computed: {
        ...mapGetters(["user"]),
        entreprise() {
            return this.user.entreprises.find(elt => elt.idEntreprise == storage.get("selected_enterprise"))
        },
    },

    data: () => ({
        proceced: false
    }),
    methods: {
        async runPrint() {
            this.proceced = true
            await this.$htmlToPaper(this.id);
            this.proceced = false
            this.$emit('input')
        }
    }
}
</script>

<style scoped>
#print-area {
    display: none;
}
#print-area header, #print-area footer {
    display: none !important;
}
#print-area.displayed {
    display: block;
}
@media print {
    #print-area {
        display: block;
    }
    #print-area header, #print-area footer {
        display: flex !important;
    }
}
</style>