<template>
    <div>
        <header class="d-flex border rounded px-3 py-1 align-items-center mb-4">
            <img :src="`${$router.options.base}img/logo-m0.png`" class="header-brand-img" alt="lavalite" style="height: 5em; width: 5em">
            <div style="flex: 1;" class="ml-1 text-center">
                <h2 class="text-danger">Maisonier</h2>   
                <p class="text-muted">{{$t('data.printer_definition_maisonier')}}</p>
            </div> 
        </header>

        <div class="mt-4 pt-4">
            <printer :display="true" v-model="printSingle" @input="printSingle = false" id="print-correspondance">
                <div v-for="occupation in occupations" :key="occupation.idOccupation">
                    <div class="d-flex justify-content-center font-weight-bold h6 text-center mb-3">
                        {{ entreprise.nom.toUpperCase() }}<br/>
                        {{ entreprise.tel }}<br/>
                        {{ entreprise.email }}<br/>
                    </div>
                    <table border="0" class="w-100">
                        <tr class="border-0">
                            <td class="text-danger">Date limite de paiement:</td>
                            <td class="text-danger font-weight-bold">05 {{ $dayjs(periodeCourante).add(1, 'month').format('MMMM YYYY') }} Heure: 18h00</td>
                            <td>Date de prélèvement:</td>
                            <td class="font-weight-bold">28-{{ $dayjs(!php.empty(occupation.indexes[0]) ? occupation.indexes[0].periode : (!php.empty(occupation.indexes[1]) ? occupation.indexes[1].periode : periodeCourante)).format('MM-YYYY') }}</td>
                        </tr>
                        <b-tr class="border-0">
                            <td>Destinataire:</td>
                            <td class="font-weight-bold">{{ nomComplet(occupation.locataire) }}</td>
                            <td>Ref:</td>
                            <td class="font-weight-bold">{{ occupation.logement.refLogement }}</td>
                        </b-tr>
                    </table>
                    <b-table-simple style="border:1px solid black !important">
                        <b-tr class="border">
                            <b-td class="border py-0 text-center" colspan="3">INDEX</b-td>
                            <b-td class="border py-0" rowspan="2">Consommation</b-td>
                            <b-td class="border py-0" rowspan="2">P.U</b-td>
                            <b-td class="border py-0" rowspan="2">Location <br>compteur</b-td>
                            <b-td class="border py-0" rowspan="2">MONTANTS</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0">Energie</b-td>
                            <b-td class="border py-0">NOUVEL</b-td>
                            <b-td class="border py-0">ANCIEN</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0">Eau</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ getConsoData(occupation, 'eau', 'nouveau') }}</b-td>
                            <b-td class="border py-0">{{ getConsoData(occupation, 'eau', 'ancien') }}</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ occupation.modeEau == 'index' ? getConsoData(occupation, 'eau', 'consommation') + ' m3' : '---' }}</b-td>
                            <b-td class="border py-0">{{ occupation.puEau }}</b-td>
                            <b-td class="border py-0">{{ occupation.modeEau == 'index' ? '0' : '---' }}</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ occupation.modeEau == 'index' ? getConsoData(occupation, 'eau', 'montant') : '---' }}</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0">Elect</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ getConsoData(occupation, 'energie', 'nouveau') }}</b-td>
                            <b-td class="border py-0">{{ getConsoData(occupation, 'energie', 'ancien') }}</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ occupation.modeEnergie == 'index' ? getConsoData(occupation, 'energie', 'consommation') + ' kw' : '---' }}</b-td>
                            <b-td class="border py-0">{{ occupation.puEnergie }}</b-td>
                            <b-td class="border py-0">{{ occupation.modeEnergie == 'index' ? '0' : '---' }}</b-td>
                            <b-td class="border py-0 font-weight-bold">{{ occupation.modeEnergie == 'index' ? getConsoData(occupation, 'energie', 'montant') : '---' }}</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0">Cable</b-td>
                            <b-td class="border py-0">---</b-td>
                            <b-td class="border py-0" colspan="4">Total a payer en Energie </b-td>
                            <b-td class="border py-0 font-weight-bold">{{ (parseInt(getConsoData(occupation, 'energie', 'montant') || 0) + parseInt(getConsoData(occupation, 'eau', 'montant') || 0)).toString().replace('NaN', '---') }}</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0" colspan="2">Base</b-td>
                            <b-td class="border py-0" colspan="2">Arrierés</b-td>
                            <b-td class="border py-0" colspan="2">Remise</b-td>
                            <b-td class="border py-0">Penalite</b-td>
                        </b-tr>
                        <b-tr class="border">
                            <b-td class="border py-0 font-weight-bold" colspan="2">{{ occupation.loyerBase | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                            <b-td class="border py-0 font-weight-bold" colspan="2">{{ occupation.arrieres | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                            <b-td class="border py-0" colspan="2">/</b-td>
                            <b-td class="border py-0">/</b-td>
                        </b-tr>
                    </b-table-simple>
                    <p>
                        Global: <br/>
                        Sauf erreur ou omission de notre part que vous voudriez bien le cas échéant nous signaler. <br />
                        Vous restez débiteur dans nos livres comptables de la somme de : 
                        <span class="d-inline-block ml-5 pl-5 font-weight-bold fa-lg">{{ getTotal(occupation) | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</span>
                    </p>
                    <h6 class="text-primary"><u>A Votre Attention !!!</u></h6>
                    <p class="font-weight-bold">
                        Les paiements peuvent aussi se faire via Orange Money, Numéro: <span class="text-primary">699152100</span><br/>
                        Vous recevrez désormais vos factures par sms via l'un de ces numéros : 656905509 / 654828824 / 664229676
                    </p>
                    <div style="max-height: 1em; overflow: hidden" class="mb-1 mt-2">{{ php.str_repeat('- ', 100) }}</div>
                </div>
            </printer>
        </div>

        <br>
        <b-button variant="danger" class="float-right mt-1" @click.prevent="printSingle = true"><i class="fa fa-print"></i> Imprimer</b-button>

    </div>
</template>

<script>
const php = require('phpjs');
import { mapGetters } from 'vuex'

import Printer from '@/components/Printer.vue'
export default {
    name: 'Correspondances',
    components: {
        Printer
    },
    props: {
        occupations: { type: Array, required: true },
        periodeCourante: { type: String, required: true}
    },
    computed: { 
        ...mapGetters(['user']),
        entreprises() {
            return php.array_slice(this.user.entreprises, 0, 5);
        },
        selected_enterprise() {
            return storage.get('selected_enterprise')
        },
        entreprise() {
            return this.entreprises.find(elt => elt.idEntreprise === this.selected_enterprise)
        }
    },
    data: () => ({
        php,
        printSingle: false,
         htmlToPdfOptions: {
            margin: [7,5,7,5],
            filename: `correspondances.pdf`,       
            image: {
                type: 'jpeg', 
                quality: 2
            },        
            enableLinks: false,
            html2canvas: {
                scale: 4, 
                logging: true,
                useCORS: false
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
            }
        },
    }),
    methods: {
        nomComplet(locataire) {
            let toutLenom = (locataire.nomLocataire || '') + ' ' + (locataire.prenomLocataire || '');
            let commande = toutLenom.split(' ')
            
            if (commande.length>=2) {
                return commande[0]+' '+commande[1];//+' '+String(commande[2]).charAt(0);
            }
            return toutLenom;
        },

        getConsoData(occupation, type, value) {
            if (type == 'energie') {
                if (occupation.modeEnergie == 'index') {
                    return !php.empty(occupation.indexes[0]) ? occupation.indexes[0][value] : 0
                }
                return '---'
            }
            if (type == 'eau') {
                if (occupation.modeEau == 'index') {
                    return !php.empty(occupation.indexes[1]) ? occupation.indexes[1][value] : 0
                }
                return '---'
            }
        },
        getTotal(occupation) {
            let total = occupation.arrieres
            
            const energie = this.getConsoData(occupation, 'energie', 'montant')
            if (php.is_int(energie)) {
                total += parseInt(energie)
            }
            
            const eau = this.getConsoData(occupation, 'eau', 'montant')
            if (php.is_int(eau)) {
                total += parseInt(eau)
            }

            return total
        },

        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {}).save()
        },
    }
}
</script>

<style>

</style>